<template>
  <div class="c-app" style="background-color: #ffffff">
    <div class="c-wrapper">
      <div class="c-body">
        <CContainer>
          <div class="lds-ripple">
            <div></div>
            <div></div>
            <p class="font-weight-bold text-success">กำลังดำเนินการ</p>
          </div>
        </CContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import payment from '@/services/payment'
import paymentutil from '@/util/payment'
import poscrm from '@/services/poscrm'
import pos from '@/services/pos'

import orderutil from '@/util/order'
import '@/util/loader.css'

export default {
  data() {
    return {
      status: '',
      charge: null,
      chargeId: '',
      paymentType: '',
      paymentStatus: '',
      paymentObjectId: '',
      cartLists: [],
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopId() {
      if (this.shop) {
        return this.shop.id
      }
    },
    orderId() {
      if (this.charge !== null) {
        return this.charge.metadata.order_id
      }
    },
    sourceType() {
      if (this.charge !== null) {
        let source = this.charge.source

        if (
          source.type === 'internet_banking_scb' ||
          source.type === 'internet_banking_bay' ||
          source.type === 'internet_banking_bbl' ||
          source.type === 'internet_banking_ktb'
        ) {
          this.paymentType = 8
        } else if (source.type === 'promptpay') {
          this.paymentType = 4
        } else if (source.type === 'credit_card') {
          this.paymentType = 1
        }

        return source.type
      }
    },
  },
  mounted() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getOrderReceived()
  },
  methods: {
    ...orderutil,
    getOrderReceived() {
      this.chargeId = sessionStorage.getItem('chargeId')

      payment({
        url: '/omise/payment/v1.0/order/received/' + this.chargeId,
        method: 'get',
      }).then((res) => {
        this.charge = res.data.data

        if (this.charge !== null) {
          this.amountForPayment = this.charge.amount / 100
          if (
            this.charge.status === 'failed' ||
            this.charge.status === 'expired' ||
            this.charge.status === 'reversed'
          ) {
            this.paymentStatus = 'T'
          } else if (
            this.charge.authorized === true &&
            this.charge.paid === true
          ) {
            this.paymentStatus = 'A'
          } else if (
            this.charge.authorized === true &&
            this.charge.capture === false
          ) {
            this.paymentStatus = 'T'
          } else if (this.charge.status === 'pending') {
            this.paymentStatus = 'P'
          }

          this.createPayment()
        }

        console.log(res)
      })
    },
    createOrder(paymentdata) {
      let data = orderutil.orderDefault(
        this.uid,
        this.shop,
        this.cartLists,
        paymentdata
      )

      pos({
        url: '/api/v1.0/' + this.uid + '/Receipt/createorder',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res.data)
        let objectId = res.data.data.objectId

        //sessionStorage.clear()
        this.$router.push(
          '/menu/order-received?objectId=' +
            objectId +
            '&chargeId=' +
            this.chargeId
        )
      })
    },
    async createPayment() {
      let paymentdata = {
        uid: this.uid,
        shop: this.shop,
        shopId: this.shopId,
        paid: this.amountForPayment,
        change: 0,
        paymentStatus: this.paymentStatus,
        paymentItems: [
          {
            paymentType: this.paymentType,
            amount: this.amountForPayment,
            inputAmount: this.amountForPayment,
            sourceType: this.sourceType,
          },
        ],
      }

      let paymentdefault = await paymentutil.paymentDefault(paymentdata)

      poscrm({
        url: '/api/v1.1/' + this.uid + '/Payment/create',
        data: paymentdefault,
        method: 'post',
      }).then((res) => {
        console.log(res.data)

        let dataForCreate = {}

        if (res.data.error.code === 0) {
          dataForCreate = {
            payReceiptAt: new Date(),
            paymentId: paymentdefault[0].id,
            payment: {
              id: paymentdefault[0].id,
              objectId: paymentdefault[0].objectId,
              paid: paymentdefault[0].paid,
              change: paymentdefault[0].change,
            },
          }
          console.log('--pay def--')
          console.log(paymentdefault)

          console.log(dataForCreate)
          this.createOrder(dataForCreate)
        } else {
          dataForCreate = null
        }
      })
    },
  },
}
</script>
